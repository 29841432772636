const addScript2 = (url) => {
	const script = document.createElement('script')
	script.src = url
	document.getElementsByTagName(`body`)[0].appendChild(script)
}

exports.onRouteUpdate = (s) => {
	
	
	addScript2(
		'//scripts.iconnode.com/58513.js'
	)

}